import React, { useContext, useEffect } from 'react'
import Layout from '../../components/Global/Layout'
import BookFreeConsult from '../../components/BookFreeConsult'
import {
  GlobalDispatchContext,
  GlobalStateContext
} from '../../context/GlobalContextProvider'

const locale = 'en-CA'

const BookFreeConsultPage = (props) => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  useEffect(() => {
    dispatch({ type: 'SET_LOCALE', payload: { locale: locale } })
  }, [dispatch, state.locale])

  return (
    <Layout
      locale={locale}
      url='/book-free-consult'
      title='Book a Free Needs Assessment'
    >
      <BookFreeConsult />
    </Layout>
  )
}

export default BookFreeConsultPage
